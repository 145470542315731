import { useContext, useEffect, useState } from "react";
import { Route, useHistory } from "react-router-dom";
import { UserContext } from "./contexts/UserContext";
import { Loader } from "./components/atoms/Loader/Loader";
import { Routes } from "./routes";
import { REGISTRATION_STATUS } from "./components/templates/RegistrationTemplate/RegistrationTemplate";

const LoggedButNotRegistredRoute = ({ component: Component, ...rest }) => {
  const [isReady, setIsReady] = useState(false);
  const { hasToken, userData, handleSwitchHomepage, getUserContext } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (!hasToken()) {
      history.push(Routes.mainPage.base);
    } else if (!userData) {
      getUserContext();
    } else if (userData?.registration_status === REGISTRATION_STATUS.COMPLETED) {
      handleSwitchHomepage();
    } else {
      setIsReady(true);
    }
  }, [hasToken, userData]);

  return isReady ? (
    <Route
      {...rest}
      render={(props) => <Component {...rest} {...props} />}
    />
  ) : (
    <Loader />
  );
};

export default LoggedButNotRegistredRoute;
