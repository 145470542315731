import {
  platform_licenses_endpoint,
  platform_users_endpoint,
  platform_organizations_endpoint,
  license_assign_endpoint,
  organization_endpoint,
  collection_endpoint,
} from "../constants/endpoints";
import { getAuthorizationHeader, preparePostFormBody } from "./repositoryHelper";

const getLicenseControl = () => {
  return fetch(platform_licenses_endpoint, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const inviteUser = (data) => {
  return fetch(`${platform_users_endpoint}add/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const resendInvitationEmail = (data) => {
  return fetch(`${platform_users_endpoint}send_activation/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const assignLicense = (id, data) => {
  return fetch(`${license_assign_endpoint + id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const addLicense = (data) => {
  return fetch(`${platform_licenses_endpoint}add/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const editLicense = (id, data) => {
  return fetch(`${platform_licenses_endpoint}edit/${id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const deleteLicense = (id) => {
  return fetch(`${platform_licenses_endpoint}remove/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody({ license_id: id }),
  });
};

const addOrganization = (data) => {
  return fetch(`${platform_organizations_endpoint}add/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const editOrganization = (id, data) => {
  return fetch(`${organization_endpoint}${id}/`, {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
    },
    body: preparePostFormBody(data),
  });
};

const getCollections = () => {
  return fetch(collection_endpoint, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

export const licenseControlRepository = {
  getLicenseControl,
  inviteUser,
  resendInvitationEmail,
  addOrganization,
  addLicense,
  editLicense,
  deleteLicense,
  assignLicense,
  editOrganization,
  getCollections,
};
