import React, { useContext } from "react";
import useFetch from "../../../hooks/useFetch";
import { competenceTestRepository } from "../../../repository/competenceTest.repository";
import { useEffect } from "react";
import { Loader } from "../../atoms/Loader/Loader";
import { languages } from "../../../dummyData/dummyData";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { PostContext } from "../../../contexts/PostContext";
import { UserContext } from "../../../contexts/UserContext";
import styles from "./Step3.module.scss";
import { CompetenceTestContextProvider } from "../../../contexts/CompetenceTestContext";
import CompetenceTestContainer from "../CompetenceTestContainer/CompetenceTestContainer";
import { Routes } from "../../../routes";
import { generatePath, useLocation } from "react-router-dom";

const Step3 = () => {
  const { isPostFinish } = useContext(PostContext);
  const { userData } = useContext(UserContext);
  const { initial_test_id } = userData;

  const location = useLocation();
  const testId = !!initial_test_id ? initial_test_id : location.state?.testId;

  useDocumentTitle(languages.EN.labels.registration);

  const fetchTest = useFetch();
  useEffect(() => {
    fetchTest.fetchData(competenceTestRepository.getTest(testId));
  }, [testId]);

  const fetchTestStatus = useFetch();
  useEffect(() => {
    if (isPostFinish || !fetchTestStatus.data) {
      fetchTestStatus.fetchData(competenceTestRepository.getTestStatus(testId));
    }
  }, [isPostFinish, testId]);

  return fetchTest.isReady ? (
    <div className={styles.step}>
      <div className={styles.contentContainer}>
        <CompetenceTestContextProvider>
          {fetchTest.data && (
            <CompetenceTestContainer
              testId={testId}
              questions={fetchTest.data}
              status={fetchTestStatus.data}
              reportUrl={{ pathname: Routes.registration.testReport.base, state: { id: testId }}}
              noTestUrl={generatePath(Routes.homepage.base)}
            />
          )}
        </CompetenceTestContextProvider>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default Step3;
