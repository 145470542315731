//User authentication
export const login_endpoint = `${process.env.REACT_APP_API_BASE}/login/`;
export const registration_login_endpoint = `${process.env.REACT_APP_API_BASE}/registration_login/`;
export const registration_endpoint = `${process.env.REACT_APP_API_BASE}/registration/`;
export const password_reset_endpoint = `${process.env.REACT_APP_API_BASE}/password_reset/`;
export const password_set_endpoint = `${process.env.REACT_APP_API_BASE}/password/reset/`;
export const password_change_endpoint = `${process.env.REACT_APP_API_BASE}/password_change/`;
export const privacy_settings_endpoint = `${process.env.REACT_APP_API_BASE}/privacy_settings/`;
export const mfa_endpoint = `${process.env.REACT_APP_API_BASE}/2fa/`;

//profile
export const profile_endpoint = `${process.env.REACT_APP_API_BASE}/profile/`;
export const user_reward_mark_claimed = `${process.env.REACT_APP_API_BASE}/user_reward/mark_claimed/`;
export const profile_image = `${process.env.REACT_APP_API_BASE}/profile_image/`;
export const endorsement_endpoint = `${process.env.REACT_APP_API_BASE}/endorsement/`;
export const user_reward_endpoint = `${process.env.REACT_APP_API_BASE}/user_reward/`;

export const team_endpoint = `${process.env.REACT_APP_API_BASE}/team/`;
export const organization_endpoint = `${process.env.REACT_APP_API_BASE}/organization/`;
export const certificates_endpoint = `${process.env.REACT_APP_API_BASE}/certificates/`;
export const workgroup_endpoint = `${process.env.REACT_APP_API_BASE}/workgroup/`;
export const competences = `${process.env.REACT_APP_API_BASE}/competence/`;
export const career_path = `${process.env.REACT_APP_API_BASE}/career_path/`;
export const career_path_job_position = `${process.env.REACT_APP_API_BASE}/career_path/job_positions/`;
export const user_learning_report_endpoint = `${process.env.REACT_APP_API_BASE}/user_learning_report/`;
export const learning_report_endpoint = `${process.env.REACT_APP_API_BASE}/learning_report/`;
export const learning_report_context_endpoint = `${process.env.REACT_APP_API_BASE}/learning_report_context/`;
export const platform_dashboard = `${process.env.REACT_APP_API_BASE}/platform/dashboard/`;

//Organization licenses/licence control
export const organizationLicenses = `${process.env.REACT_APP_API_BASE}/my_organization/licenses/`;
export const license_endpoint = `${process.env.REACT_APP_API_BASE}/license/`;
export const platform_licenses_endpoint = `${process.env.REACT_APP_API_BASE}/platform/licenses/`;
export const platform_organizations_endpoint = `${process.env.REACT_APP_API_BASE}/platform/organizations/`;
export const platform_users_endpoint = `${process.env.REACT_APP_API_BASE}/platform/users/`;
export const invoice_endpoint = `${process.env.REACT_APP_API_BASE}/invoice/download/`;
export const platform_invoices_endpoint = `${process.env.REACT_APP_API_BASE}/platform/invoices/`;
export const organizationUnassingLicense = `${process.env.REACT_APP_API_BASE}/license/unassign/`;
export const license_assign_endpoint = `${process.env.REACT_APP_API_BASE}/license/assign/`;
export const downloadContract = `${process.env.REACT_APP_API_BASE}/org_contract/download/`;

//courses
export const courses = `${process.env.REACT_APP_API_BASE}/courses/`;
export const course_assign_endpoint = `${process.env.REACT_APP_API_BASE}/course_assign/`;
export const courseDetails = `${process.env.REACT_APP_API_BASE}/course_details/`;
export const courseMaterial = `${process.env.REACT_APP_API_BASE}/course_material/download/`;
export const course_likes_endpoint = `${process.env.REACT_APP_API_BASE}/course_likes/`;
export const course_share_endpoint = `${process.env.REACT_APP_API_BASE}/course_share/`;
export const courseEnroll = `${process.env.REACT_APP_API_BASE}/course_enroll/`;

//Individual development plan
export const idp_endpoint = `${process.env.REACT_APP_API_BASE}/idp/`;
export const idp_delete_endpoint = `${process.env.REACT_APP_API_BASE}/idp_delete/`;
export const idp_comment_endpoint = `${process.env.REACT_APP_API_BASE}/idp_comment/`;
export const learning_plan_endpoint = `${process.env.REACT_APP_API_BASE}/learning_plan/`;

export const search = `${process.env.REACT_APP_API_BASE}/search/`;

//Home page
export const user_homepage = `${process.env.REACT_APP_API_BASE}/user_homepage/`;
export const bonus_buy = `${process.env.REACT_APP_API_BASE}/bonus/buy/`;
export const course_suggestion = `${process.env.REACT_APP_API_BASE}/course_suggestion/`;
export const competition_entry = `${process.env.REACT_APP_API_BASE}/competition_entry/`;
export const lottery_tickets = `${process.env.REACT_APP_API_BASE}/lottery/tickets/`;

//chat
export const chatroom = `${process.env.REACT_APP_API_BASE}/chatroom/`;
export const chat = `${process.env.REACT_APP_API_BASE}/chat/`;
export const chat_search = `${process.env.REACT_APP_API_BASE}/chat_search/`;
export const chatbot_endpoint = `${process.env.REACT_APP_API_BASE}/chatbot/`;

//Notifications
export const notification_endpoint = `${process.env.REACT_APP_API_BASE}/notification/`;
export const notification_settings_endpoint = `${process.env.REACT_APP_API_BASE}/notification_settings/`;

//Registration Competences & Test
export const initial_test_endpoint = `${process.env.REACT_APP_API_BASE}/initial_test/`;
export const user_competence_endpoint = `${process.env.REACT_APP_API_BASE}/user_competence/`;
export const test_endpoint = `${process.env.REACT_APP_API_BASE}/test/`;
export const problem_answer_endpoint = `${process.env.REACT_APP_API_BASE}/problem_answer/`;
export const problem_feedback_endpoint = `${process.env.REACT_APP_API_BASE}/problem_feedback/`;

export const view_context_endpoint = `${process.env.REACT_APP_API_BASE}/view_context/`;
export const profession_endpoint = `${process.env.REACT_APP_API_BASE}/profession/`;
export const education_endpoint = `${process.env.REACT_APP_API_BASE}/education/`;
export const experience_endpoint = `${process.env.REACT_APP_API_BASE}/experience/`;
export const notes_endpoint = `${process.env.REACT_APP_API_BASE}/notes/`;
export const marketplace_endpoint = `${process.env.REACT_APP_API_BASE}/marketplace/`;
export const my_organization_endpoint = `${process.env.REACT_APP_API_BASE}/my_organization/`;
export const job_positions_endpoint = `${process.env.REACT_APP_API_BASE}/job_position/`;
export const job_position_competence_endpoint = `${process.env.REACT_APP_API_BASE}/job_position_competence/`;
export const competence_endpoint = `${process.env.REACT_APP_API_BASE}/competence/`;
export const competence_group_endpoint = `${process.env.REACT_APP_API_BASE}/competence_group/`;
export const competence_level_endpoint = `${process.env.REACT_APP_API_BASE}/competence_level/`;
export const courses_endpoint = `${process.env.REACT_APP_API_BASE}/courses/`;
export const course_content_endpoint = `${process.env.REACT_APP_API_BASE}/course_content/`;
export const course_content_pdf_endpoint = `${process.env.REACT_APP_API_BASE}/course_content_pdf/`;
export const course_material_pdf_endpoint = `${process.env.REACT_APP_API_BASE}/course_material_pdf/`;
export const user_video_statistics_endpoint = `${process.env.REACT_APP_API_BASE}/user_video_statistics/`;
export const user_pdf_statistics_endpoint = `${process.env.REACT_APP_API_BASE}/user_pdf_statistics/`;
export const user_material_statistics_endpoint = `${process.env.REACT_APP_API_BASE}/user_material_statistics/`;
export const course_review_endpoint = `${process.env.REACT_APP_API_BASE}/course_review/`;
export const course_edit_endpoint = `${process.env.REACT_APP_API_BASE}/course_edit/`;
export const course_audit_endpoint = `${process.env.REACT_APP_API_BASE}/course_audit/`;
export const collection_endpoint = `${process.env.REACT_APP_API_BASE}/collection/`;
export const documents_endpoint = `${process.env.REACT_APP_API_BASE}/platform/documents/`;
export const document_audit_endpoint = `${process.env.REACT_APP_API_BASE}/platform/document_audit/`;
export const instructor_endpoint = `${process.env.REACT_APP_API_BASE}/instructor/`;
export const instructor_dashboard_endpoint = `${process.env.REACT_APP_API_BASE}/instructor_dashboard/`;
export const instructor_review_endpoint = `${process.env.REACT_APP_API_BASE}/instructor_review/`;
export const learning_event_endpoint = `${process.env.REACT_APP_API_BASE}/learning_event/`;
export const calendar_event_endpoint = `${process.env.REACT_APP_API_BASE}/calendar_event/`;
export const regulatory_intelligence_endpoint = `${process.env.REACT_APP_API_BASE}/regulatory_intelligence/`;
export const regulatory_intelligence_likes_endpoint = `${process.env.REACT_APP_API_BASE}/ri_likes/`;
export const regulatory_intelligence_share_endpoint = `${process.env.REACT_APP_API_BASE}/ri_share/`;
export const tracking_event_endpoint = `${process.env.REACT_APP_API_BASE}/tracking_event/`;
