import React from "react";
import Text from "../../atoms/Text/Text";
import styles from "./ListItemImgMultiInfo.module.scss";
import RoundImage from "../../atoms/RoundImage/RoundImage";
import TextTruncated from "../../atoms/TextTruncated/TextTruncated";
import ListItemImgInfoStyles from "../ListItemImgInfo/ListItemImgInfo.module.scss";
import { languages } from "../../../dummyData/dummyData";

const ListItemImgMultiInfo = ({ infos }) => {
  return (
    <span className={ListItemImgInfoStyles.listItemImgInfo}>
        <div className={styles.names}>
          <TextTruncated lineClamp={2}>
            {infos.length > 0 ? infos.map((info, i) => (
              <React.Fragment key={i}>
                <Text s12 lh24 dark-6>
                  {info.name}
                </Text>
                <br />
              </React.Fragment>
            )) : languages.EN.placeholders.empty}
          </TextTruncated>
        </div>
        <div className={styles.images}>
          {infos.map((info, i) => (
            <RoundImage key={i} image={info.img} size={50} />
          ))}
        </div>
      </span>
  );
};

export default ListItemImgMultiInfo;
