import {
  organizationLicenses,
  organizationUnassingLicense,
  downloadContract,
} from "../constants/endpoints";
import { getAuthorizationHeader } from "./repositoryHelper";

const getUserOrganizationLicenses = () => {
  return fetch(organizationLicenses, {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  });
};

const downloadOrganizationContract = (id, type) => {
  return fetch(downloadContract + id + "/", {
    method: "GET",
    headers: {
      ...getAuthorizationHeader(),
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${type}_${id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
};

const unassignLicense = (id, user) => {
  return fetch(organizationUnassingLicense + id + "/", {
    method: "POST",
    headers: {
      ...getAuthorizationHeader(),
      "content-type": "application/x-www-form-urlencoded",
    },
    body: `user=${user}`,
  });
};

export const organizationLicensesRepository = {
  getUserOrganizationLicenses,
  unassignLicense,
  downloadOrganizationContract,
};
