import React, { useRef, useState } from "react";
import styles from "./TextTruncated.module.scss";

const TextTruncated = ({ children, secondary, lineClamp, topBanner, style = {} }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const ref = useRef();

  const resizeRef = () => {
    if (ref.current !== null) {
      setIsTruncated(ref.current && ref.current.scrollHeight - 1 > ref.current.clientHeight);
    }
  };

  return (
    <div data-secondary={secondary} className={styles.TextWrapper} data-banner={topBanner} onMouseEnter={resizeRef}>
      <span className={styles.TextWrapper__lineClamp} ref={ref} style={{ WebkitLineClamp: lineClamp }}>
        {children}
      </span>
      {isTruncated && (
        <span className={styles.truncated} data-banner={topBanner} style={style}>
          {children}
        </span>
      )}
    </div>
  );
};

export default TextTruncated;
