import React, { useContext, useEffect, useState } from "react";
import styles from "./CompetenceTestContainer.module.scss";
import Text from "../../atoms/Text/Text";
import ProgressBar, { VARIANT_TABLE_PRIMARY } from "../../molecules/ProgressBar/ProgressBar";
import CompetenceTestQuestion from "../CompetenceTestQuestion/CompetenceTestQuestion";
import Button from "../../atoms/Button/Button";
import { ACTIONS, PopupsContext } from "../../../contexts/PopupsContext";
import { languages } from "../../../dummyData/dummyData";
import { competenceTestRepository } from "../../../repository/competenceTest.repository";
import ReactPaginate from "react-paginate";
import { CompetenceTestContext } from "../../../contexts/CompetenceTestContext";
import { useHistory } from "react-router-dom";
import { PostContext } from "../../../contexts/PostContext";
import { ReportProblemContext } from "../../../contexts/ReportProblemContext";

const { paragraph1, paragraph2, paragraph3 } = languages.EN.labels.test;

const CompetenceTestContainer = ({ testId, questions, status, reportUrl, noTestUrl }) => {
  const pages = Math.ceil(questions.length);

  const [progressBarValue, setProgressBarValue] = useState(0);
  const [formData, setFormData] = useState();
  const [isFinished, setIsFinished] = useState(false);

  const { currentAnswer, setCurrentAnswer, currentPosition, setCurrentPosition, savedAnswers, setSavedAnswers } = useContext(CompetenceTestContext);
  const { callPopup } = useContext(PopupsContext);
  const { postData } = useContext(PostContext);
  const { setSubject } = useContext(ReportProblemContext);

  const history = useHistory();

  useEffect(() => {
    if (status) {
      setSavedAnswers(Object.fromEntries(status.done_problems.map(problem => [problem.position, problem.answer])));
      setProgressBarValue(Math.round((status.done_problems.length / pages) * 100));
    }

    if (isFinished && formData !== undefined) {
      handleFinishTest();
    }
  }, [status]);

  useEffect(() => {
    setCurrentAnswer(savedAnswers[currentPosition]);
  }, [savedAnswers])

  useEffect(() => {
    if (status) {
      const donePositions = status.done_problems.map((item) => item.position);

      [...document.querySelectorAll(".page-link")].map((pageItem) => {
        const pageNumber = parseInt(pageItem.innerHTML);

        if (donePositions.includes(pageNumber)) {
          pageItem.className = "page-link page-link__active";
        }
      });
    }
  }, [status, currentPosition]);

  useEffect(() => {
    if (formData) {
      postData(() => competenceTestRepository.postTestAnswer(testId, formData));
    }
  }, [formData]);

  useEffect(() => {
    if (isFinished && formData === undefined) {
      handleFinishTest();
    }
  }, [isFinished]);

  useEffect(() => {
    if (questions.length > 0) {
      setSubject(`${languages.EN.labels.question}: ${questions[currentPosition - 1].question}`);
    }
  }, [currentPosition]);

  useEffect(() => {
    if (questions.length === 0) {
      callPopup({
        type: ACTIONS.INFORMATION,
        payload: {
          header: languages.EN.labels.information,
          content: languages.EN.messages.thereAreNoQuestions,
          buttonLabel: languages.EN.labels.ok,
          action: () => {
            postData(
              () => competenceTestRepository.finishTest(testId),
              () => {
                callPopup({ type: ACTIONS.REMOVE });
                history.push(noTestUrl);
              }
            );
          },
        },
      })
    }
  }, [questions]);

  const handleFinishTest = () => {
    setIsFinished(false);
    callPopup({
      type: ACTIONS.CONFIRM,
      payload: {
        header: languages.EN.labels.confirmation,
        content: `${paragraph1} ${status ? status.done_problems.length : 0} ${paragraph2} ${questions.length}. ${paragraph3}`,
        action: () => {
          postData(
            () => competenceTestRepository.finishTest(testId),
            () => {
              callPopup({ type: ACTIONS.REMOVE });
              history.push(reportUrl);
            }
          );
        },
      },
    })
  }

  return (
    <div>
      {questions.length > 0 && (
        <section className={styles.questionBox}>
          <div>
            <div className={styles.ProgressBar__centering}>
              <div className={styles.ProgressBar__wrapper}>
                <ProgressBar variant={VARIANT_TABLE_PRIMARY} progress={progressBarValue} />
              </div>
            </div>
            <div className={styles.content}>
              <Text s20 lh32 w800 secondary>
                {`${languages.EN.labels.question} ${currentPosition} of ${pages}`}
              </Text>
              <CompetenceTestQuestion data={questions[currentPosition - 1]} />
            </div>
          </div>

          <div className={styles.pagination}>
            <ReactPaginate
              nextLabel=">"
              onPageChange={(event) => {
                setFormData(currentAnswer !== savedAnswers[currentPosition] ? { item_id: questions[currentPosition - 1].block_id, answer: currentAnswer } : undefined);

                const nextPage = event.selected + 1;
                setCurrentAnswer(savedAnswers[nextPage]);
                setCurrentPosition(() => nextPage);
              }}
              pageRangeDisplayed={Math.min(20, pages)}
              marginPagesDisplayed={1}
              forcePage={currentPosition - 1}
              initialSelected={currentPosition - 1}
              pageCount={pages}
              previousLabel="<"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="previousButton"
              previousLinkClassName="previousLink"
              nextClassName="nextButton"
              nextLinkClassName="nextLink"
              breakLabel="···"
              breakClassName="page-item"
              breakLinkClassName="breakLink"
              containerClassName="reactPagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
            <Button
              variant="primary"
              onClick={() => {
                setFormData(currentAnswer !== savedAnswers ? { item_id: questions[currentPosition - 1].block_id, answer: currentAnswer } : undefined);
                setIsFinished(true);
              }}>
              <Text s12 lh14 w500>
                {languages.EN.labels.end}
              </Text>
            </Button>
          </div>
        </section>
      )}
    </div>
  );
};
export default CompetenceTestContainer;
